exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-nasz-zespol-js": () => import("./../../../src/pages/Nasz-zespol.js" /* webpackChunkName: "component---src-pages-nasz-zespol-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-newsletter-potwierdz-subskrypcje-js": () => import("./../../../src/pages/newsletter-potwierdz-subskrypcje.js" /* webpackChunkName: "component---src-pages-newsletter-potwierdz-subskrypcje-js" */),
  "component---src-pages-o-fundacji-js": () => import("./../../../src/pages/O-fundacji.js" /* webpackChunkName: "component---src-pages-o-fundacji-js" */),
  "component---src-pages-webinary-js": () => import("./../../../src/pages/webinary.js" /* webpackChunkName: "component---src-pages-webinary-js" */),
  "component---src-pages-wspieram-js": () => import("./../../../src/pages/wspieram.js" /* webpackChunkName: "component---src-pages-wspieram-js" */),
  "component---src-pages-wspolpraca-js": () => import("./../../../src/pages/wspolpraca.js" /* webpackChunkName: "component---src-pages-wspolpraca-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

